@media only screen and (min-width: 1440px) {
  .page {
    max-width: 1200px;
    margin: 0 auto;
  }

  #header-desktop .header-wrapper,
  #header-mobile .header-wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media only screen and (max-width: 1440px) {
  .page {
    width: 56%;
  }
}

@media only screen and (max-width: 1200px) {
  .page {
    width: 52%;
  }

  #header-desktop .header-wrapper {
    padding-right: 1rem;
  }

  .search-dropdown.desktop {
    right: 1rem;
  }
}

@media only screen and (max-width: 960px) {
  #toc-auto {
    display: none;
  }

  #toc-static {
    display: block;
  }

  .page {
    width: 80%;
  }

  #header-desktop .header-wrapper {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 680px) {
  #header-desktop {
    display: none;
  }

  #header-mobile {
    display: block;
  }

  body.blur {
    overflow: hidden;
  }

  .page {
    width: 100%;

    [data-header-mobile] & {
      padding-top: var(--header-height);
    }

    [data-header-mobile=normal] & {
      padding-top: 0;
    }

    .categories-card {
      .card-item {
        width: 100%;
      }
    }
  }

  .copyright {
    .copyright-line {
      .icp-splitter {
        display: none;
      }
      .icp-br {
        display: block;
      }
    }
  }
}
